import React from "react";
import "./wedding.css";
import Helmet from "react-helmet";
const downloadIcon = require("./images/icon-download-big.svg");
const WeddingDownload = props => {
  return (
    <div className="container">
      <Helmet>
        <title>Wedding Songs: Tabs, notes and mp3s download page</title>
      </Helmet>
      <header role="banner">
        <nav role="navigation">
          <h1 className="main-title">
            <span className="main-title-name">Bringing Sound to Life</span>
            <span className="main-title-tagline">
              Download Page for: Here Comes The Bride and Wedding Outro. Easy
              Tabs
            </span>
          </h1>
          <button className="toggle" aria-expanded="false" />
        </nav>
      </header>
      <main role="main">
        <section className="product-download" role="contentinfo">
          <div className="product-download-header">
            <div className="product-download-icon" />
            <div className="product-download-header-text">
              Download page for: Easy Tabs for Bridal Chorus (Here Comes The
              Bride), and Wedding March (wedding outro)
            </div>
          </div>
          <div className="intro-text intro-text-body">
            Congratulations and thank you for your purchase! You now hold the
            tools needed to learn these songs fast! These arrangements are the
            easiest way to play the songs. <br />
            <br />
            <a
              href="https://s3-us-west-2.amazonaws.com/bringing-sound-to-life/downloads/Wedding_Song_Tabs_Play_the_Easy_Way.zip"
              className="download-link"
            >
              <span className="download-individual-text">
                This is your download link (.zip file - 6.5 MB download)
                <br />
                Save to your computer and "unzip" contents (8 files in a folder
                called "Wedding Song Tabs - Play the Easy Way")
              </span>
              <br />
              <img
                alt="start download button"
                style={{
                  backgroundColor: "rebeccapurple",
                  borderRadius: 20,
                  padding: 20,
                  marginTop: 15
                }}
                src={downloadIcon}
              />
            </a>
            <br />
            <br />
            The .zip file you are about to download contains 8 files:
            <ul className="product-features">
              <li>
                My easy arrangement of the Bridal Chorus -- 2 versions! One with
                tabs AND musical notation on the same sheet, and one with tabs
                only.
              </li>
              <li>
                My easy arrangement of The Wedding March -- 2 versions! One with
                tabs AND musical notation on the same sheet, and one with tabs
                only.
              </li>
              <li>
                You will be able to practice along with a high quality (320kbps)
                mp3 of each song (2 separate mp3 files).
              </li>
              <li>A midi file of each song (2 separate files).</li>
            </ul>
            <br />
            <br />
            <a href="/hctb_aspx_login_rdto_redirToCatalog_aspx_rdtoParams_goto_rto_rdtoParamValues_04012019_file_myDownloads">
              <span className="download-individual-text">
                Are you on an iPad, or unable to use the .zip file? If so, click
                here to download the individual files.
              </span>
            </a>
            <br />
            <br />
            Thank you for your purchase!
            <div className="thank-you-small">
              <br />
              I am a therapeutic musician who plays bedside prescriptive music
              for people in health care settings. I also provide music for
              weddings and events.
              <br />
              Your purchase provides much appreciated support while I develop a
              career in playing music for people in healthcare settings.
            </div>
            <br />
            On a mobile device now?
            <div className="thank-you-small">
              <br />
              Not to worry, you will also receive a download link at the email
              address connected to your PayPal / AmazonPay account. You can use
              the link to download these files when you are on a desktop/laptop
              and can save the files. The link will be sent automatically when I
              receive notification of your completed payment. PayPal usually
              does this immediately, while AmazonPay is usually immediate, or in
              rare cases can take up to an hour. Thank you again for your
              purchase!
            </div>
          </div>
        </section>
      </main>

      <footer role="contentinfo">
        <ul>
          {/*   <!-- li Payment Safety-->*/}
          {/*   <!-- li Satisfaction Guaranteed-->*/}
          {/*<!-- li Privacy Policy-->*/}
          <span className="attribution">
            Alex Jacobs &copy; {new Date().getFullYear()} . All Rights Reserved
          </span>
        </ul>
      </footer>
    </div>
  );
};

export default WeddingDownload;
