import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import Helmet from "react-helmet";
import constants from "../../assets/constants";
import { LinkCard } from "../LinkCard/LinkCard";

const styles = {
  root: {
    flexGrow: 1,
    marginTop: 20
  },
  card: {
    maxWidth: 275,
    marginBottom: 70
  },
  link: {
    textDecoration: "none"
  }
};

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { classes } = this.props;
    return (
      <>
        <Helmet>
          <title>
            About Alex Jacobs, Therapeutic Musician and Sound Healer
          </title>
        </Helmet>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="flex-start"
          className={classes.root}
        >
          <LinkCard
            classes={classes}
            linkTo={constants.WEDDING_TABS_PAGE}
            content="I'm getting ready to compile a biography and list of personal teachers that I have worked with since I began my music studies in grade school. While I get things set up, please feel free to check out some basic information about me via the tabs above. In th/e meantime this text is a link to my Easy Wedding Song Tablature and Mp3 Download Page."
          />
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(About);
