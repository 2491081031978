import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Tab, Tabs } from '@material-ui/core/';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import './Header.css';

const styles = {
  root: {
    flexGrow: 1
  }
};

function Header(props) {
  const { classes, location } = props;

  const tabs = [
    { label: 'Home', to: '/' },
    { label: 'About', to: '/about' },
    { label: 'Music', to: '/music' },
    { label: 'Contact', to: '/contact' }
  ].map((x, i) => <Tab key={i} component={Link} label={x.label} to={x.to} />);

  return (
    <div>
      <AppBar position="static" color="inherit">
        <Toolbar>
          <Tabs
            className={classes.root}
            centered
            value={tabs.findIndex((x) => {
              return x.props.to === location.pathname;
            })}
          >
            {tabs}
          </Tabs>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withStyles(styles)(withRouter(Header));
