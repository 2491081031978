import { Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const ContentCard = props => {
  let { classes } = props;
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography style={{ padding: 20 }} variant="body1">
          {props.content}
        </Typography>
      </CardContent>
    </Card>
  );
};

const LinkCard = props => {
  let { classes } = props;
  return (
    <Link className={classes.link} to={props.linkTo}>
      <ContentCard classes={classes} content={props.content} />
    </Link>
  );
};
export { ContentCard, LinkCard };
