import React from "react";
import "./wedding.css";
import Helmet from "react-helmet";
const WeddingDownload = props => {
  return (
    <div className="container">
      <Helmet>
        <title>Wedding Songs: Individual files download page</title>
      </Helmet>
      <header role="banner">
        <nav role="navigation">
          <h1 className="main-title">
            <span className="main-title-name">Bringing Sound to Life</span>
            <span className="main-title-tagline">
              Individual File Download Page for: Here Comes The Bride and
              Wedding Outro. Easy Tabs
            </span>
          </h1>
          <button className="toggle" aria-expanded="false" />
        </nav>
      </header>
      <main role="main">
        <section className="product-download" role="contentinfo">
          <div className="product-download-header">
            <div className="product-download-icon" />
            <div className="product-download-header-text">
              Individual File Download page for: Easy Tabs for Bridal Chorus
              (Here Comes The Bride), and Wedding March (wedding outro)
            </div>
          </div>
          <div className="intro-text intro-text-body">
            Congratulations and thank you for your purchase! You now hold the
            tools needed to learn these songs fast! These arrangements are the
            easiest way to play the songs. <br />
            <ul className="product-features individual-file-download" id="">
              <li>
                <a href="https://s3-us-west-2.amazonaws.com/bringing-sound-to-life/wedding-files/Bridal_Chorus_Here_Comes_The_Bride_tab_only.pdf">
                  Bridal Chorus (Here Comes the Bride) - Tab Only (option/right
                  click and save-as)
                </a>
              </li>

              <li>
                <a href="https://s3-us-west-2.amazonaws.com/bringing-sound-to-life/wedding-files/Bridal_Chorus_Here_Comes_The_Bride.pdf">
                  Bridal Chorus (Here Comes the Bride) - Tab and Notes
                  (option/right click and save-as)
                </a>
              </li>

              <li>
                <a href="https://s3-us-west-2.amazonaws.com/bringing-sound-to-life/wedding-files/The_Wedding_March_outro_song_tab_only.pdf">
                  Wedding March Outro Song - Tab Only (option/right click and
                  save-as)
                </a>
              </li>

              <li>
                <a href="https://s3-us-west-2.amazonaws.com/bringing-sound-to-life/wedding-files/The_Wedding_March_outro_song.pdf">
                  Wedding March Outro Song - Tab and Notes (option/right click
                  and save-as)
                </a>
              </li>

              <li>
                <a href="https://s3-us-west-2.amazonaws.com/bringing-sound-to-life/wedding-files/Wedding_March_Play_the_Easy_Way.mp3">
                  Wedding March - mp3 (option/right click and save-as)
                </a>
              </li>

              <li>
                <a href="https://s3-us-west-2.amazonaws.com/bringing-sound-to-life/wedding-files/Bridal_Chorus_Play_the_Easy_Way.mp3">
                  Bridal Chorus - mp3 (option/right click and save-as)
                </a>
              </li>

              <li>
                <a href="https://s3-us-west-2.amazonaws.com/bringing-sound-to-life/wedding-files/The_Wedding_March_outro_song.mid">
                  Wedding March - midi (option/right click and save-as)
                </a>
              </li>

              <li>
                <a href="https://s3-us-west-2.amazonaws.com/bringing-sound-to-life/wedding-files/Bridal_Chorus_Here_Comes_the_Bride.mid">
                  Bridal Chorus - midi (option/right click and save-as)
                </a>
              </li>
            </ul>
            <br />
            <br />
            Thank you for your purchase!
            <div className="thank-you-small">
              <br />
              I am a therapeutic musician who plays bedside prescriptive music
              for people in health care settings. I also provide music for
              weddings and events.
              <br />
              Your purchase provides much appreciated support while I develop a
              career in playing music for people in healthcare settings.
            </div>
            <br />
            On a mobile device now?
            <div className="thank-you-small">
              <br />
              Not to worry, you will also receive a download link at the email
              address connected to your PayPal / AmazonPay account. You can use
              the link to download these files when you are on a desktop/laptop
              and can save the files. The link will be sent automatically when I
              receive notification of your completed payment. PayPal usually
              does this immediately, while AmazonPay is usually immediate, or in
              rare cases can take up to an hour. Thank you again for your
              purchase!
            </div>
          </div>
        </section>
      </main>

      <footer role="contentinfo">
        <ul>
          {/*   <!-- li Payment Safety-->*/}
          {/*   <!-- li Satisfaction Guaranteed-->*/}
          {/*<!-- li Privacy Policy-->*/}
          <span className="attribution">
            Alex Jacobs &copy; {new Date().getFullYear()} . All Rights Reserved
          </span>
        </ul>
      </footer>
    </div>
  );
};

export default WeddingDownload;
