import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import Home from "../Home/Home";
import About from "../About/About";
import Music from "../Music/Music";
import Contact from "../Contact/Contact";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Privacy from "../Privacy/Privacy";
import Terms from "../Terms/Terms";
import Wedding from "../Wedding/Wedding";
import WeddingDownload from "../Wedding/WeddingDownload";
import WeddingDownloadFiles from "../Wedding/WeddingDownloadFiles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { white } from "@material-ui/core/colors";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    background: {
      default: white
    }
  }
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <div className="App">
            <Route
              path="(/|/about|/contact|/music)"
              render={props => <Header {...props} />}
            />
            <Switch>
              <Route exact path="/" render={props => <Home {...props} />} />
              <Route
                exact
                path="/about"
                render={props => <About {...props} />}
              />
              <Route
                exact
                path="/contact"
                render={props => <Contact {...props} />}
              />
              <Route
                exact
                path="/music"
                render={props => <Music {...props} />}
              />
              <Route
                exact
                path="/privacy-policy"
                render={props => <Privacy {...props} />}
              />
              <Route
                exact
                path="/terms-and-conditions"
                render={props => <Terms {...props} />}
              />
              <Route
                path="(/wedding|/wedding-song-easy-tabs|/tabs)"
                render={props => <Wedding {...props} />}
              />
              <Route
                path="(/tabs-download|/hctb_aspx_login_rdto_redirToCatalog_aspx_rdtoParams_goto_rto_rdtoParamValues_04012019_myDownloads)"
                render={props => <WeddingDownload {...props} />}
              />
              <Route
                path="/hctb_aspx_login_rdto_redirToCatalog_aspx_rdtoParams_goto_rto_rdtoParamValues_04012019_file_myDownloads"
                render={props => <WeddingDownloadFiles {...props} />}
              />
              <Route
                path="/"
                render={props => (
                  <Typography variant={"h4"}>
                    Nothing at this url
                    <br />
                    <br />
                    <Link to="/">Head back to the homepage?</Link>
                  </Typography>
                )}
              />
            </Switch>
            <Route
              path="(/|/about|/contact|/music)"
              render={props => <Footer {...props} />}
            />
          </div>
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
