/* eslint-disable indent */
import React from "react";
import ReCaptcha from "react-google-recaptcha";
import {
  Checkbox,
  Fab,
  FormControlLabel,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import NavigationIcon from "@material-ui/icons/Navigation";
import { validate } from "email-validator";

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  }
});

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      email: "",
      hp: "",
      name: "",
      recaptureValue:
        process.env.NODE_ENV === "development" ? "development" : null,
      validationComplete: false
    };
  }
  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };
  handleCheckbox = name => event => {
    this.setState({ [name]: event.target.checked });
  };
  handleClick = event => {
    event.preventDefault();
    fetch("/api/v0/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(Object.assign({}, this.state))
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(response => {
        this.setState({ validationComplete: response });
      })
      .catch(error => {
        console.log("error", error);
      });
  };
  handleSuccess = value => {
    this.setState({ recaptureValue: value });
  };
  render() {
    const { classes } = this.props;
    return this.state.validationComplete ? (
      <Typography variant="h6">**You are subscribed!**</Typography>
    ) : (
      <Typography component="div">
        <form>
          <TextField
            className={classes.textField}
            margin="normal"
            variant="outlined"
            label="First Name (optional)"
            type="text"
            name="name"
            id="name"
            value={this.state.name}
            onChange={this.handleChange("name")}
          />
          <br />
          <TextField
            className={classes.textField}
            margin="normal"
            variant="outlined"
            label="Email *required"
            type="email"
            name="email"
            id="email"
            value={this.state.email}
            onChange={this.handleChange("email")}
          />
          <div style={{ display: "none" }}>
            <TextField
              className={classes.textField}
              margin="normal"
              variant="outlined"
              label="Occupation"
              type="text"
              name="occupation"
              id="occupation"
              value={this.state.hp}
              onChange={this.handleChange("hp")}
            />
          </div>
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.checked}
                onChange={this.handleCheckbox("checked")}
              />
            }
            label="Marketing permission: I give my consent to Bringing Sound To Life to be in touch with me via email using the information I have
            provided in this form for the purpose of news, updates and marketing."
          />
          <br />
          <br />
          <ReCaptcha onChange={this.handleSuccess} sitekey={SITE_KEY} />
          <br />
          <Fab
            disabled={
              !(
                this.state.checked === true &&
                this.state.email !== "" &&
                validate(this.state.email) === true &&
                this.state.recaptureValue !== null
              )
            }
            aria-label="Submit"
            variant="extended"
            size="medium"
            color="primary"
            type="submit"
            className={classes.margin}
            onClick={this.handleClick}
          >
            <NavigationIcon className={classes.extendedIcon} />
            Submit
          </Fab>
          <br />
          <br />
          <br />
          <Typography>
            <strong>What to expect</strong>: If you wish to withdraw your
            consent and stop hearing from us, simply click the unsubscribe link
            at the bottom of every email we send or contact us at
            unsubscribe@bringingsoundtolife.com. We value and respect your
            personal data and privacy. To view our privacy policy, please visit
            our website. By submitting this form, you agree that we may process
            your information in accordance with these terms. In brief: your
            personal information will never be sold or shared for any reason
            except if required by law.
          </Typography>
        </form>
      </Typography>
    );
  }
}

export default withStyles(styles)(Signup);
