import { Card, CardContent, Typography } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
import Signup from "../Signup/Signup";

const styles = {
  card: {
    minWidth: 275,
    marginBottom: 70
  },
  followCard: {
    marginTop: 10,
    marginBottom: 10
  }
};

function SimpleCard(props) {
  const { classes } = props;

  return (
    <React.Fragment>
      <Helmet>
        <title>Let's be in touch</title>
        <meta
          name="description"
          content="This is how to stay in touch with the musical happenings of Alex Jacobs. New music releases will be shared with my mailing list, as well as social media communities. Please follow me via the links available at this page."
        />
      </Helmet>
      <Card className={classes.followCard}>
        <CardContent>
          <Typography variant="body1">
            <a href="https://www.instagram.com/bringingsoundtolife/">
              Follow me on Instagram!
            </a>
          </Typography>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5">
            Please sign up for my mailing list. I'd love to tell you about new
            music and free giveaways.
          </Typography>
          <br />
          <Signup />
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SimpleCard);
