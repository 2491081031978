import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography } from "@material-ui/core/";
import { Link } from "react-router-dom";

const styles = {
  grow: {
    flexGrow: 1
  },
  appBar: {
    top: "auto",
    bottom: 0
  },
  links: {
    color: "white",
    marginRight: 20,
    textDecoration: "none"
  }
};

function Footer(props) {
  const { classes } = props;

  return (
    <div>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.grow} />
          <Typography variant="caption">
            <Link className={classes.links} to="privacy-policy">
              Privacy
            </Link>
          </Typography>
          <Typography variant="caption">
            <Link className={classes.links} to="terms-and-conditions">
              Toc
            </Link>
          </Typography>
          <div />
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withStyles(styles)(Footer);
