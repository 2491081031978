import React, { Component } from "react";
import Helmet from "react-helmet";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { LinkCard } from "../LinkCard/LinkCard";
import constants from "../../assets/constants";

const styles = {
  root: {
    flexGrow: 1,
    marginTop: 20
  },
  card: {
    maxWidth: 275,
    marginBottom: 70
  },
  link: {
    textDecoration: "none"
  }
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { classes } = this.props;
    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="Sparking freedom through creative musical expression, with the tools you have now. And just to clarify, when I say the tools you have now I don’t necessarily mean you’ve mastered every technique about your instrument, but the tools you have now are your ability to set goals, practice, and grow. And through those tools you have now, along with the resources that I’m sharing with you, you can continue to evolve on your journey of creative musical expression."
          />
        </Helmet>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="flex-start"
          className={classes.root}
        >
          <LinkCard
            classes={classes}
            linkTo={constants.WEDDING_TABS_PAGE}
            content="Welcome to my site! While I get things set up, please feel free to check out some basic information about me via the tabs above. In the meantime this text is a link to my Easy Wedding Song Tablature and Mp3 Download Page."
          />
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(Home);
