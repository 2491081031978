import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import Helmet from "react-helmet";
import constants from "../../assets/constants";
import { LinkCard } from "../LinkCard/LinkCard";

const styles = {
  root: {
    flexGrow: 1,
    marginTop: 20
  },
  card: {
    maxWidth: 275,
    marginBottom: 70
  },
  link: {
    textDecoration: "none"
  }
};

class Music extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { classes } = this.props;
    return (
      <>
        <Helmet>
          <title>Music, tablature, and specialty sound recordings</title>
          <meta
            name="description"
            content="Alex Jacobs has been publicly releasing albums and specialty sound recordings since 2008. This is where you can explore his public catalog."
          />
        </Helmet>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="flex-start"
          className={classes.root}
        >
          <LinkCard
            classes={classes}
            linkTo={constants.WEDDING_TABS_PAGE}
            content={
              'I\'m going to be using this section to share music and albums that I have produced. New materials will be linked in this section, and shared with mailing list and social media community. Please head over to the "contact" tab to connect with me! While I get things set up, please feel free to check out some basic information about me via the tabs above. In th/e meantime this text is a link to my Easy Wedding Song Tablature and Mp3 Download Page.'
            }
          />
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(Music);
