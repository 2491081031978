import React from "react";
import { Helmet } from "react-helmet";
import "./wedding.css";
const buyNow = require("./images/buy-now.png");
const tabSample = require("./images/image-slideshow-3x.png");
const tabSampleBackground = require("./images/image-slideshow-background.jpg");
const TAB_PRICE = 10;
const stripeLink = process.env.REACT_APP_STRIPE_LINK;


const Wedding = props => {
  return (
    <div className="container">
      <Helmet>
        {/* <script
          async
          src="https://static-na.payments-amazon.com/OffAmazonPayments/us/js/Widgets.js"
        /> */}
        <title>
          How to Play Here Comes the Bride and The Wedding March on Guitar.
          Tabs, notes and mp3s download
        </title>
        <meta
          name="description"
          content="This is the easiest way to play the traditional wedding songs on guitar. The Bridal Chorus (also known as Here Comes the Bride) and The Wedding March (also known as the Wedding Outro). Download the tabs and mp3s here."
        />
      </Helmet>
      <div className="mobile-menu-container-background" />
      <div className="mobile-menu-container">
        <button className="close-element" aria-expanded="true" />
        <ul className="header-tabs-mobile">
          <li>
            <span>Home</span>
          </li>
          <li>
            <span>About</span>
          </li>
          <li>
            <span>Samples</span>
          </li>
          <li>
            <span>Testimonials</span>
          </li>
          <li>
            <span>Legal &amp; Privacy</span>
          </li>
        </ul>
      </div>
      <header role="banner">
        <nav role="navigation">
          <h1 className="main-title">
            <span className="main-title-name">Bringing Sound to Life</span>
            <span className="main-title-tagline">
              Easy Tabs for: Here Comes The Bride and Wedding Outro
            </span>
          </h1>
          <button className="toggle" aria-expanded="false" />
          <ul className="header-tabs">
            <li>Home</li>
            <li>About</li>
            <li>Samples</li>
            <li>Testimonials</li>
            <li>Legal &amp; Privacy</li>
          </ul>
        </nav>
      </header>
      <main role="main">
        <section className="splash-image" role="contentinfo">
          <div className="subtle-gradient" />
          {/* <div className="price-sticker" /> */}
          <div className="price-tag">{TAB_PRICE}</div>
          <h2 className="product-title">
            <div className="product-title-top">Easy Guitar Tabs for:</div>
            <div className="product-title-middle">Bridal Chorus &</div>
            <div className="product-title-bottom">Wedding March</div>
          </h2>
          <div className="payment-buttons">
            <a href={stripeLink}><img src={buyNow} alt="buy now"></img></a>
          </div>
        </section>
        <section className="intro-text" role="contentinfo">
          <div className="intro-text-header">
            If you don't have the time or experience to learn a complex
            arrangement, this is for you!
          </div>
          <div className="intro-text-body">
            My simple arrangement will save you considerable time and effort in
            learning these songs. Drawing from my {new Date().getFullYear() - 1991} years of musical experience, including playing therapeutic music for people in healthcare settings, I
            made these arrangements with the goal of saving you time, and
            keeping them as simple as possible to play on guitar.
            <br />
            <br />
            After wasting hours looking online for accurate, easy tabs, I
            put my years of guitar playing experience to use for the
            benefit of other musicians. I made a beautiful, simple, and complete
            arrangement that I want to share with you. It's easy, accurate, and
            guaranteed to be exactly what you need to learn the traditional
            intro and outro songs
          </div>
          <div className="intro-text-header">
            Organizing your wedding? Purchase this for your musical friend or
            family member that you want to ask to play during your entrance! You
            will received a download link that you can send to them.
          </div>
          <a href="#more-info">
            <div className="intro-text-continue">Learn More</div>
            <div className="intro-text-continue-icon" />
          </a>
        </section>
        <section
          id="more-info"
          className="product-description"
          role="contentinfo"
        >
          <div className="product-description-header">
            <div className="product-description-icon" />
            <div className="product-description-header-text">
              For only ${TAB_PRICE} you get:
            </div>
          </div>
          <div className="product-description-body">
            <ul className="product-features">
              <li>
                Everything a beginning guitar player needs to learn these songs.
              </li>
              <li>
                My easy arrangement of the Bridal Chorus -- <b>2 versions!</b>
              </li>
              <li>
                One with <b>tabs & musical notation</b> on the same sheet, and
                one with tabs only.
              </li>
              <li>
                My easy arrangement of The Wedding March -- <b>2 versions!</b>
              </li>
              <li>
                One with <b>tabs & musical notation</b> on the same sheet, and
                one with tabs only.
              </li>
              <li>
                You will be able to practice along with a <b>high quality</b>{" "}
                (320kbps) mp3 of each song (2 separate mp3 files).
              </li>
              <li>
                A <b>midi file of each song</b> (2 separate files).
              </li>
            </ul>
          </div>
        </section>
        <section className="download-instructions" role="contentinfo">
          <div className="download-instructions-header">
            <div className="download-instructions-icon" />
            <div className="download-instructions-header-text">
              How to buy and download:
            </div>
          </div>
          <div className="download-instructions-body">
            <ul className="download-features">
              <li>
                Pay safely and easily with any credit or debit card. Payments handled by Stripe (one of the largest and most trusted online payment processors). Your payment information remains 100% private.
              </li>
              <li>
                After payment, you will be automatically redirected to the
                download page.
              </li>
              <li>Instantly download your purchase and start playing!</li>
              <li>
                I guarantee you will be completely satisfied. Otherwise contact
                me for a refund, no questions asked.
              </li>
              <li>
                On a mobile device? No problem, you will also be emailed a
                download link at the email address you enter when paying.
              </li>
            </ul>
          </div>
        </section>
      </main>
      <h3 className="tablature-sample-heading">Tablature / Notation Sample</h3>
      <div className="tablature-sample">
        <img
          alt="tablature sample background"
          className="tablature-sample-background"
          src={tabSampleBackground}
        />
        <img
          alt="tablature sample background"
          className="tablature-sample-image"
          src={tabSample}
        />
      </div>
      <h3 className="testimonials-heading">Testimonials</h3>
      <div className="testimonial-container">
        <div className="testimonial-card">
          <div className="testimonial-heart" />
          <div className="testimonial-text">
            Mr Jacobs, your tab and arrangement of the Wedding March is
            EXCELLENT and a great help. It allowed me to learn it all in a
            flash. I had many many compliments on how this sounded. It worked
            out great.
            <br />
            <br />
            -D. Ball
          </div>
        </div>
      </div>
      <div className="testimonial-container">
        <div className="testimonial-card">
          <div className="testimonial-heart" />
          <div className="testimonial-text">
            You are right Lex; This was the fastest and easy way the learn these
            songs! The arrangements are simple and elegant.
            <br />
            <br />
            -B. Norris
          </div>
        </div>
      </div>
      <div className="testimonial-container">
        <div className="testimonial-card">
          <div className="testimonial-heart" />
          <div className="testimonial-text">
            Very glad I found your site, regular YouTube videos were just not
            really helping me and I have just over three weeks to be prepared.
            <br />
            <br />
            -Z. Purinton{" "}
          </div>
        </div>
      </div>
      <div className="testimonial-container">
        <div className="testimonial-card">
          <div className="testimonial-heart" />
          <div className="testimonial-text">
            Hey, thank you so much for this. I was able to learn the song easily
            and I'm much less stressed about playing at this wedding now!
            <br />
            -W. Bernal <br />{" "}
          </div>
        </div>
      </div>
      <div className="testimonial-container">
        <div className="testimonial-card">
          <div className="testimonial-heart" />
          <div className="testimonial-text">
            Hey, Lex! Thanks for this simplified and pretty version. You're a
            good teacher. I have two weeks to learn this. I was asked by my
            sister to play her march down the aisle. Thanks!
            <br />
            <br />
            -P. Graham
          </div>
        </div>
      </div>
      <div className="payment-buttons-bottom">
        <a href={stripeLink}><img src={buyNow} alt="buy now"></img></a>
      </div>
      <footer role="contentinfo">
        <ul>
          <span className="attribution">
            Alex Jacobs &copy; {new Date().getFullYear()} . All Rights Reserved
          </span>
        </ul>
      </footer>
    </div>
  );
};

export default Wedding;
